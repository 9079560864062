.upload {
  &-zone {
    min-height: 200px;
    position: relative;
    &.hover::before {
      content: '';
      position: absolute;
      z-index: 2;
      left: 10px;
      right: 10px;
      top: 10px;
      bottom: 10px;
      background: rgba(255, 255, 255, 0.8);
      border: dashed 2px red;
    }

    &.file-control {
      min-height: 32px;
      line-height: 32px;
      position: relative;
      input[type='file'] {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        appearance: none;
      }
    }

  }
  &-area {
    padding: 15px;
    display: flex;
    min-height: 200px;
    justify-content: center;
    align-items: center;
  }
}
